import React from "react";
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from "configs/AppConfig";

export const publicRoutes = [
  {
    key: "login",
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login")
    ),
  },
  {
    key: "set-password",
    path: `${AUTH_PREFIX_PATH}/setPassword`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/set-password")
    ),
  },
  {
    key: "forgot-password",
    path: `${AUTH_PREFIX_PATH}/forgotPassword`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/forgot-password")
    ),
  },
  {
    key: "verify-2fa",
    path: `${AUTH_PREFIX_PATH}/verify-2fa`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/2fa/2fa-page")
    ),
  },
];

export const protectedRoutes = [
  {
    key: "default",
    path: `${APP_PREFIX_PATH}/dashboard`,
    component: React.lazy(() => import("views/app-views/dashboards/default")),
    access: ["Admin"],
  },
  {
    key: "dashboard-client",
    path: `${APP_PREFIX_PATH}/dashboard-client`,
    component: React.lazy(() => import("../pages/DashboardClient")),
    access: ["Client"],
  },
  {
    key: "dashboard-trader",
    path: `${APP_PREFIX_PATH}/dashboard-trader`,
    component: React.lazy(() => import("../pages/DashboardTrader")),
    access: ["Trader"],
  },
  {
    key: "bets",
    path: `${APP_PREFIX_PATH}/bets`,
    component: React.lazy(() => import("../pages/Bets")),
    access: ["Admin"],
  },
  {
    key: "bets-client",
    path: `${APP_PREFIX_PATH}/bets-client`,
    component: React.lazy(() => import("../pages/BetsClient")),
    access: ["Client"],
  },
  {
    key: "bets-import",
    path: `${APP_PREFIX_PATH}/bets-import`,
    component: React.lazy(() => import("../pages/BetsImport")),
    access: ["Admin"],
  },
  {
    key: "bet-add",
    path: `${APP_PREFIX_PATH}/bet-add`,
    component: React.lazy(() => import("../pages/BetAdd")),
    access: ["Admin", "Trader"],
  },
  {
    key: "users",
    path: `${APP_PREFIX_PATH}/users`,
    component: React.lazy(() => import("../pages/Users")),
    access: ["Admin"],
  },
  {
    key: "user-details",
    path: `${APP_PREFIX_PATH}/users/profile`,
    component: React.lazy(() => import("../pages/UserProfile")),
    access: ["Admin", "Client"],
  },
  {
    key: "user-settings",
    path: `${APP_PREFIX_PATH}/users/settings`,
    component: React.lazy(() => import("../pages/UserSettings")),
    access: ["Admin", "Client", "Trader"],
  },
  {
    key: "user-new",
    path: `${APP_PREFIX_PATH}/users/new`,
    component: React.lazy(() => import("../pages/UserAdd")),
    access: ["Admin"],
  },
  {
    key: "user-edit",
    path: `${APP_PREFIX_PATH}/users/edit/:id`,
    component: React.lazy(() => import("../pages/UserEdit")),
    access: ["Admin"],
  },
  {
    key: "customers",
    path: `${APP_PREFIX_PATH}/customers`,
    component: React.lazy(() => import("../pages/Customers")),
    access: ["Admin"],
  },
  {
    key: "customer-new",
    path: `${APP_PREFIX_PATH}/customers/new`,
    component: React.lazy(() => import("../pages/CustomerAdd")),
    access: ["Admin"],
  },
  {
    key: "customer-edit",
    path: `${APP_PREFIX_PATH}/customers/edit/:id`,
    component: React.lazy(() => import("../pages/CustomerEdit")),
    access: ["Admin"],
  },
  {
    key: "transactions",
    path: `${APP_PREFIX_PATH}/transactions`,
    component: React.lazy(() => import("../pages/Transactions")),
    access: ["Admin"],
  },
  {
    key: "transactions-client",
    path: `${APP_PREFIX_PATH}/transactions-client`,
    component: React.lazy(() => import("../pages/TransactionsClient")),
    access: ["Client"],
  },
  {
    key: "profit-loss",
    path: `${APP_PREFIX_PATH}/profit-loss`,
    component: React.lazy(() => import("../pages/ProfitLoss")),
    access: ["Admin"],
  },
  {
    key: "profit-loss-chart",
    path: `${APP_PREFIX_PATH}/profit-loss-chart`,
    component: React.lazy(() => import("../pages/ProfitLossChart")),
    access: ["Admin"],
  },
  {
    key: "profit-loss-client",
    path: `${APP_PREFIX_PATH}/profit-loss-client`,
    component: React.lazy(() => import("../pages/ProfitLossClient")),
    access: ["Client"],
  },
  {
    key: "profit-loss-chart-client",
    path: `${APP_PREFIX_PATH}/profit-loss-chart-client`,
    component: React.lazy(() => import("../pages/ProfitLossChartClient")),
    access: ["Client"],
  },
  {
    key: "login-history",
    path: `${APP_PREFIX_PATH}/login-history`,
    component: React.lazy(() => import("../pages/LoginHistory")),
    access: ["Admin"],
  },
  {
    key: "profit-loss-trader",
    path: `${APP_PREFIX_PATH}/profit-loss-trader`,
    component: React.lazy(() => import("../pages/ProfitLossTrader")),
    access: ["Trader"],
  },
  {
    key: "profit-loss-chart-trader",
    path: `${APP_PREFIX_PATH}/profit-loss-chart-trader`,
    component: React.lazy(() => import("../pages/ProfitLossChartTrader")),
    access: ["Trader"],
  },
  {
    key: "stats-football",
    path: `${APP_PREFIX_PATH}/stats-football`,
    component: React.lazy(() => import("../pages/StatsFootball")),
    access: ["Admin"],
  },
  {
    key: "stats-tennis",
    path: `${APP_PREFIX_PATH}/stats-tennis`,
    component: React.lazy(() => import("../pages/StatsTennis")),
    access: ["Admin"],
  },
  {
    key: "leagues-football",
    path: `${APP_PREFIX_PATH}/leagues-football`,
    component: React.lazy(() => import("../pages/LeaguesFootball")),
    access: ["Admin"],
  },
  {
    key: "competition-tennis",
    path: `${APP_PREFIX_PATH}/competition-tennis`,
    component: React.lazy(() => import("../pages/CompetitionTennis")),
    access: ["Admin"],
  },
  {
    key: "charts",
    path: `${APP_PREFIX_PATH}/charts`,
    component: React.lazy(() => import("../pages/Echart")),
    access: ["Admin"],
  },
];
