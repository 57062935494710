import {
  NAV_TYPE_SIDE,
  DIR_LTR,
  SIDE_NAV_DARK,
  SIDE_NAV_LIGHT,
} from "constants/ThemeConstant";
import { env } from "./EnvironmentConfig";

export const APP_NAME = "Trader Pro";
export const API_BASE_URL = env.API_ENDPOINT_URL;
export const APP_PREFIX_PATH = "/bep";
export const AUTH_PREFIX_PATH = "/auth";
export const REDIRECT_URL_KEY = "redirect";
export const AUTHENTICATED_ENTRY = `${APP_PREFIX_PATH}/dashboard`;
export const UNAUTHENTICATED_ENTRY = "/login";
export const TWO_FACTOR_AUTH = "/two-factor-authentication";

export const THEME_CONFIG = {
  navCollapsed: true,
  sideNavTheme: SIDE_NAV_LIGHT,
  locale: "en",
  navType: NAV_TYPE_SIDE,
  topNavColor: "#3e82f7",
  // headerNavColor: "#ffb80c",
  mobileNav: true,
  currentTheme: "light",
  direction: DIR_LTR,
  blankLayout: false,
};
